<template>
    <div class="container m-5">
        <div v-if="blogPosted" class="blog-posted-container">
            <h4>Blog Posted Successfully</h4>
            <p>Your blog will be live after its approved by the admin.</p>
            <button class="btn btn-secondary" @click="$router.push({ name: 'blogs' })">
                Go to Blogs
            </button>
        </div>
        <div v-else>
            <Loader :isLoading="processingForm" />
            <h4 class="text-center">Create New Blog</h4>
            <div class="form-container">
                <hr />
                <form class="form" @submit.prevent="submitBlog">
                    <div class="form-group">
                        <label>Title </label>
                        <input type="text" class="form-control" v-model="title" />
                    </div>
                    <div class="form-group">
                        <label>Tag </label>
                        <input type="text" class="form-control" v-model="tag" />
                    </div>
                    <div class="form-group">
                        <label>Description </label>
                        <textarea v-model="description" rows="10" class="form-control"></textarea>
                    </div>
                    <button class="primary-button">Post blog</button>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex"
import request from "../../apis/request"
import scrollTop from "../utils/scrollTop"
import Loader from "../Loader.vue"

export default {
    data() {
        return {
            title: "",
            tag: "",
            description: "",
            blogPosted: false,
            processingForm: false,
        }
    },
    components: {
        Loader,
    },
    computed: {
        ...mapGetters("auth", {
            isMaterialTester: "isMaterialTester",
        }),
    },
    async mounted() {
        scrollTop()
        if (this.isMaterialTester) {
            this.$toasted.error("Sorry! You are not allowed to post blogs!")
            return this.$router.replace({ name: "landing" })
        }
        await this.checkBlogPostAllowed()
    },
    methods: {
        async checkBlogPostAllowed() {
            try {
                await request.get("/blog/check-allowed")
            } catch (err) {
                const message =
                    err && err.response && err.response.data && err.response.data.message
                this.$toasted.error(message || "You are not allowed to create blogs.")
                this.$router.replace({ name: "blogs" })
            }
        },
        async submitBlog() {
            if (this.processingForm) return
            if (!this.title || !this.description || !this.tag) {
                return this.$toasted.error("Please fill up all the fields")
            }
            this.processingForm = true
            try {
                const res = await request.post("/blog", {
                    title: this.title,
                    tag: this.tag,
                    description: this.description,
                })
                this.$toasted.success("Blog posted successfully.")
                this.blogPosted = true
            } catch (err) {
                console.log(err)
                this.$toasted.error("Error in posting blog. Try again later.")
            }
            this.processingForm = false
        },
    },
}
</script>

<style lang="scss" scoped>
.form-container {
    margin: 0 auto;
    max-width: 600px;
}
.blog-posted-container {
    background-color: hsl(120, 100%, 90%);
    padding: 20px;
    padding-top: 30px;
    text-align: center;
}
</style>
